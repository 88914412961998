import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ProductRightColumnProps, IProduct } from '@typesApp/product'
import { ProductNameSection } from '../ProductNameSection'
import ProductCtaContainer from '../ProductCtaContainer'
import ProductAvailableNotificationDrawer from '../ProductAvailableNotificationDrawer'
import { ProductDetailsList } from '../ProductDetailsList'
import ProductSize from '../ProductSize'
import {
  RightColumnErrorContainer,
  StyledFramesRightColumnContainer,
  StyledImageWrapper,
  StyledRelatedProduct,
  StyledSeparator,
} from '../../ProductDetails.style'
import ProductColorSelector from '../../components/ProductColorSelector'
import { getCurrentProductItemByUniqId, getMerchandisingProducts } from '../../../../utils/product'
import Log from '../../../../services/Log'
import { useTranslation } from 'next-i18next'
import ProductImage from '../../../../components/ProductImage/ProductImage'
import { get } from 'lodash-es'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { useTheme } from '@mui/material/styles'
import { getNormalizedProductType, getSoldOut } from '../../../../utils/productAttributes'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '../../../../constants/product'
import { ShippingTimesBanner } from '../ShippingTimesBanner/ShippingTimesBanner'
import { ProductPriceAlgolia } from '../ProductPriceAlgolia'
import { getProductAlgoliaPrice } from '../../../../foundation/algolia/algoliaPrice'
import { Prescription } from '../../../../components/PrescriptionLenses/Prescription'
import { prescriptionLensFormOpenSelector } from '../../../../features/rox/roxSlice'
import { getCustomerSegmentsfromCookie } from '../../../../features/plp/algoliaUtils'
import { addToCartBusySelector } from '@features/cartui/cartuiSlice'
import { useRouter } from 'next/router'
import { addSubscriptionToCartErrorSelector } from '@features/product/selector'
import { StyledSpecialFrameInfo } from '../StyledSpecialFrameInfo'
// import { addToCartBusySelector } from '@features/cartui/cartuiSlice'
import styles from './styles/PdpRight.module.scss'
import { TrustPilotMiniWidget } from '@components/TrustPilotReviews/TrustPilotWidget'
import useBreakpoints from '@hooks/useBreakpoints'
import { isSoldOutFeatureEnabled } from '@utils/common'

export const FramesPdpRightColumn = (props: ProductRightColumnProps) => {
  const {
    isLoading,
    partNumber,
    currentProduct,
    soldOutStatus,
    productInCart,
    addToCartButtonFail,
    ctaRef,
    cluster,
    onClusterProductClick,
    isSoldout,
    isRoxable,
    type,
    customerSegments,
    productQuantity,
  } = props

  const { langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const theme = useTheme()
  const router = useRouter()
  const { isDesktop } = useBreakpoints()
  const isPrescriptionLensFormOpen = useSelector(prescriptionLensFormOpenSelector)
  const isAddToCartBusy = useSelector(addToCartBusySelector)
  const areAllClusterItemsSoldOut = cluster?.length
    ? cluster.every(i => getSoldOut(i) === PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    : false
  const [relatedProducts, setRelatedProducts] = useState<IProduct[]>()
  const [isSelectLensesBusy, setSelectLensesBusy] = useState(isAddToCartBusy || isPrescriptionLensFormOpen)
  const renderColorSelector = !areAllClusterItemsSoldOut && cluster && cluster?.length > 1
  const customerSegment = getCustomerSegmentsfromCookie()
  const addSubscriptionToCartError = useSelector(addSubscriptionToCartErrorSelector)
  const soldOutFeatureEnabled = isSoldOutFeatureEnabled()
  const displayable = currentProduct.displayable

  useEffect(() => {
    try {
      if (cluster) {
        const merchandisingProducts = getMerchandisingProducts(partNumber, cluster)

        if (merchandisingProducts === null) {
          setRelatedProducts([])
        } else {
          const merchandisingItems = merchandisingProducts.filter(
            item => item.associationType?.toString().toLowerCase() === 'optics'
          )
          setRelatedProducts(merchandisingItems)
        }
      }
    } catch (e: any) {
      Log.error('Could not retry product details data: ' + e.message)
    }
  }, [cluster])

  const getAttachments = (cluster: IProduct) => get(cluster, 'cluster[0].attachments', [])

  const getItems = (product: IProduct) => {
    return product.sKUs != null ? product.sKUs : product.items
  }

  const getMocoLinkTo = (cluster: IProduct | undefined) => {
    const product = get(cluster, 'cluster[0]')
    const items = product && getItems(product)
    const href = (items && get(items[0], 'seo.href')) || ''
    const finalHref = `/${langCode}${href}`
    return finalHref
  }

  const onSizeChange = useCallback(
    (id: string | undefined) => {
      const currentProductItem = !!id ? getCurrentProductItemByUniqId(id, cluster) : null
      if (!currentProductItem) return
      router.push(`${currentProductItem?.seo?.href}`)
    },
    [router, langCode, cluster]
  )

  const algoliaPrices = getProductAlgoliaPrice(customerSegment, currentProduct || undefined)
  let productType = getNormalizedProductType(currentProduct)
  //We want the SUN products type to be considered as frames so the sticky bar shows the  price that matches the Frame right column
  if (productType === PRODUCT_TYPES_KEYS.SUN) {
    productType = PRODUCT_TYPES_KEYS.FRAMES
  }
  const showProductDetailsList = soldOutFeatureEnabled || (currentProduct && !isSoldout)
  const showProductCtas = soldOutFeatureEnabled || (currentProduct && displayable)

  return (
    <>
      <StyledFramesRightColumnContainer>
        {currentProduct && (
          <ProductNameSection currentProduct={currentProduct} isSoldout={isSoldout} displayable={displayable} />
        )}
        {isDesktop && currentProduct && <TrustPilotMiniWidget sku={partNumber} />}
        {currentProduct && (
          <ProductSize
            isLoading={isLoading}
            onSizeChange={onSizeChange}
            currentProduct={currentProduct}
            cluster={cluster}
            partNumber={partNumber}
          />
        )}
        {currentProduct && (
          <>
            {!areAllClusterItemsSoldOut && <StyledSpecialFrameInfo currentProduct={currentProduct} />}
            {!isSoldout &&
              relatedProducts &&
              relatedProducts.map((product, index) => (
                <StyledRelatedProduct key={product.partNumber} href={getMocoLinkTo(product)}>
                  <StyledImageWrapper>
                    <ProductImage
                      attachments={getAttachments(product)}
                      backgroundColor={theme.palette.background.light.primary}
                      preload={index === 0}
                    />
                  </StyledImageWrapper>
                  <span>
                    {type === 'sun'
                      ? t('ProductDetails.Suggested.ShopInOptical')
                      : t('ProductDetails.Suggested.ShopInSun')}
                  </span>
                </StyledRelatedProduct>
              ))}
            {renderColorSelector && !isSoldout && <StyledSeparator />}
          </>
        )}
        {renderColorSelector && (
          <ProductColorSelector
            currentProductItem={currentProduct}
            isLoading={isLoading}
            cluster={cluster}
            selectedPartNumber={partNumber}
            soldOutStatus={soldOutStatus}
            onClick={onClusterProductClick}
          />
        )}

        <div className={styles.container}>
          {displayable && (
            <ProductPriceAlgolia
              isCompact
              soldOutStatus={soldOutStatus}
              productQuantity={productQuantity}
              totalBoxes={1}
            />
          )}
          {showProductCtas && (
            <ProductCtaContainer
              productInCart={productInCart}
              isLoading={isLoading}
              soldOutStatus={soldOutStatus}
              currentProduct={currentProduct}
              roxable={isRoxable}
              error={addToCartButtonFail}
              ctaRef={ctaRef}
              addToCartFillType={'outline'}
              productQuantity={productQuantity}
              algoliaPrices={algoliaPrices}
              isSelectLensesBusy={isSelectLensesBusy}
              setSelectLensesBusy={setSelectLensesBusy}
            />
          )}
          <RightColumnErrorContainer>
            {addSubscriptionToCartError && t('Subscriptions.Errors.PendingSubscriptionInCart')}
          </RightColumnErrorContainer>
          {!soldOutFeatureEnabled && <ShippingTimesBanner productType={PRODUCT_TYPES_KEYS.FRAMES} />}
        </div>
        <ProductAvailableNotificationDrawer
          attachments={currentProduct?.attachments || []}
          attributes={currentProduct?.attributes || []}
          product={currentProduct || undefined}
          soldOutStatus={soldOutStatus}
        />
        {showProductDetailsList && (
          <ProductDetailsList type={soldOutStatus} isLoading={isLoading} currentProduct={currentProduct} />
        )}
      </StyledFramesRightColumnContainer>
      {isPrescriptionLensFormOpen && <Prescription setSelectLensesBusy={setSelectLensesBusy} />}
    </>
  )
}
