// import { useDispatch, useSelector } from 'react-redux'
import { useDynamicCmsContentPlpAlgolia } from '@foundation/hooks/useDynamicCmsContent'
import { Refinement } from '@utils/Plp/PlpReducer'
import React from 'react'

const usePlpPlacements = ({ plpCommerce }, appliedFacets = [] as Refinement[]) => {
  const placementsPlpCommerce = useDynamicCmsContentPlpAlgolia(plpCommerce?.commercePlacements || [], appliedFacets) ?? []
  const plpDescription = plpCommerce?.PLPDescription || {
    numFound: 0,
    result: [],
  }

  const preFooter = (placementsPlpCommerce ?? []).find(placement => placement.name === 'footer_before_footer')

  React.useEffect(() => {
    // TODO: dispatch(setPreFooter(preFooter!))
  }, [preFooter])
  return {
    placementsPlpCommerce,
    plpDescription,
  }
}

export default usePlpPlacements
