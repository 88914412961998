import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useSearchParams } from 'next/navigation'
import { useTranslation } from 'next-i18next'

// CONSTANTS
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '@constants/product'
import config from '../../configs/config.base'

// COMPONENTS
import { getBrand, getModelName, getNormalizedProductType } from '../../utils/productAttributes'
import BrandIcon from '@components/BrandIcon'
import { BreadcrumbLayout } from '@components/breadcrumb'
import PlacementLayout from '@components/Cms/PlacementLayout'
import {
  TrustBox,
  TrustPilotMiniWidget,
  isTrustPilotEnabledForLocale,
} from '@components/TrustPilotReviews/TrustPilotWidget'
import { ProductDetailsHead } from './components/Head'
import PDPStickyBar from './components/PdpStickyBar/PDPStickyBar'
import ProductDetailsSkeleton from './components/ProductDetailsSkeleton'
import ProductFullPageGallery from './components/ProductFullPageGallery'
import ProductImageNotAvailable from './components/ProductImageNotAvailable'
import ProductImageZoom from './components/ProductImageZoom/ProductImageZoom'
import ProductImagesThumbnails from './components/ProductImagesThumbnails'
import { AccessoriesPdpRightColumn } from './components/ProductRightColumn/AccessoriesPdpRightColumn'
import { CLAccessoriesPdpRightColumn } from './components/ProductRightColumn/CLAccessoriesPdpRightColumn'
import { ContactLensesPdpRightColumn } from './components/ProductRightColumn/ContactLensesPdpRightColumn'
import { FramesPdpRightColumn } from './components/ProductRightColumn/FramesPdpRightColumn'
import ProductSoldoutBanner from './components/ProductSoldoutBanner'
import { ProductSoldoutContainer, StyledSoldOutBrandIconWrapper } from './components/ProductSoldoutBanner.style'
import VirtualMirrorCTA from './components/VirtualMirrorCTA/VirtualMirrorCTA'

import { ICategory } from '@features/category/query'
import { orderItemsSelector } from '@features/order/selector'
import { getCustomerSegmentsfromCookie } from '@features/plp/algoliaUtils'

import { usePageType } from '@foundation/hooks/usePageType'
import { useSite } from '@foundation/hooks/useSite'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { monetateTrackPdp } from '@foundation/monetate/lib'
import { MONETATE_CART_ITEMS } from '@foundation/monetate/monetateConstants'
import { localStorageUtil } from '@foundation/utils/storageUtil'

import useBreakpoints from '@hooks/useBreakpoints'
import { useCurrentProductSelection } from '@hooks/useCurrentProductSelection'
import { useEditCartItem } from '@hooks/useEditCartItem'
import { retrieveProductImages } from '@hooks/useProductImages/useProductImages'

import {
  pdpAccImageOrderSelector,
  pdpClImageOrderSelector,
  pdpFrameImageOrderSelector,
  plpBadgesSelector,
} from '@redux/selectors/site'

// TYPES
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { Adjustment, OrderItem } from '@typesApp/order'
import {
  AssociatedProduct,
  Attachment,
  IProduct,
  ProductRightColumnProps,
  ProductSoldOutStatus,
  ProductType,
} from '@typesApp/product'

// UTILS
import { sortImageByConf } from '@utils/attachmentsUtils'
import { getParsedOrderItems } from '@utils/order'
import { isAccessories, isCLAccessories, isContactLenses, isVirtualMirrorEligible } from '@utils/product'
import { getBadges, getProductType } from '@utils/productAttributes'

import {
  PdpContainer,
  PdpLeftColumn,
  PdpRightColumn,
  ProductImagesMainSlider,
  SliderWrapper,
  StyledLeftColumnContainer,
  StyledRightColumnContainer,
  StyledTrustBoxContainer,
  TrustPilotMiniWidgetContainer,
} from './ProductDetails.style'
import { useProductSoldOutState } from './useProductSoldOutState'
import { getProductImageAltLabel } from '@utils/productImage'
import { getFormattedSubscriptionRecurrency } from '@views/Subscription/helpers/subscriptionHelpers'
import { SubscriptionInfo } from '@typesApp/subscription'
import useIsInViewport from '@hooks/useIsInViewport'
import { determineAlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import { isWunderkindEnabledForLocale } from '@components/Wunderkind/Wunderkind'
import { PDPPageData } from '@foundation/analytics/tealium/lib'
import { isSoldOutFeatureEnabled } from '@utils/common'
import { ProductBadges } from '@components/features/ProductBadges'
import { addCLAccessoriesToCartErrorSelector } from '@features/product/selector'
import styles from './styles/ProductDetails.module.scss'

export interface ProductDetailsProps {
  partNumber: string
  pdpData: IProduct
  loading: boolean
  loadingCommercePlacements: boolean
  placements?: IPlacement[]
  breadcrumbs?: any[]
  onClusterProductClick?: (p: IProduct) => void
  onSizeChange?: (id: string | undefined, cluster: IProduct[] | undefined) => void
  soldOutStatus: ProductSoldOutStatus
  currentProduct: IProduct
  setCurrentProduct?: (currentProduct: IProduct) => void
  categoryData?: ICategory[]
  isVmProduct: boolean
  isRoxable: boolean
  suggestedProducts: AssociatedProduct[] | undefined
  analyticsPdpData: PDPPageData | undefined
  availableQuantity?: number
  onAddToCartClick?: () => void
  liveStockError?: boolean
}

export const getRightColumnContentType = (productType: ProductType | null | string): ProductType | null | string => {
  if (!productType) return null

  return isContactLenses(productType) || isAccessories(productType) || isCLAccessories(productType)
    ? productType
    : PRODUCT_TYPES_KEYS.FRAMES
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  partNumber,
  pdpData,
  onClusterProductClick,
  placements,
  breadcrumbs,
  loading,
  loadingCommercePlacements,
  currentProduct,
  isRoxable,
  isVmProduct,
  analyticsPdpData,
  availableQuantity,
  onAddToCartClick,
  soldOutStatus,
  liveStockError = false,
}) => {
  const { t: translate } = useTranslation()
  const [fullPageGalleryVisible, setFullPageGalleryVisible] = useState<boolean>(false)
  const [zoomLayerVisible, setZoomLayerVisible] = useState<boolean>(false)
  const { isDesktop } = useBreakpoints()
  const { mySite } = useSite()
  const { pageType } = usePageType()

  const pdpFrameImageOrder = useSelector(pdpFrameImageOrderSelector, shallowEqual)
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const pdpAccImageOrder = useSelector(pdpAccImageOrderSelector, shallowEqual)
  const pdpClImageOrder = useSelector(pdpClImageOrderSelector, shallowEqual)
  const addCLAccessoriesToCartError = useSelector(addCLAccessoriesToCartErrorSelector)

  const currentImages = currentProduct ? retrieveProductImages(currentProduct) : []
  const isLoading = !currentProduct

  const [addToCartCtaRef, setAddToCartCtaRef] = useState<React.RefObject<HTMLDivElement>>({ current: null })
  const [stickybarVisible, setStickyBarVisible] = useState<boolean>(false)
  const [productQuantity, setProductQuantity] = React.useState<string[]>(['1'])

  const damDomain: string = mySite.xStoreCfg
    ? mySite.xStoreCfg['damDomain'] || config.defaultDamDomain
    : config.defaultDamDomain
  const images: Attachment[] = currentImages
  const ctaRef = useRef<HTMLDivElement>(null)
  const stickyBarCtaRef = useRef<HTMLDivElement>(null)
  const orderItems = useSelector(orderItemsSelector)
  const productType = currentProduct && getNormalizedProductType(currentProduct)
  const query = useSearchParams()
  const areImagesAvailable = images && images.length > 0
  const groupedOrderId = query.get('groupedOrderId')
  const rightColumnContentType = getRightColumnContentType(productType)
  const customerSegments = getCustomerSegmentsfromCookie()
  const badgeSelector = useSelector(plpBadgesSelector)
  const { isRXEnabled } = useStoreIdentity()
  const productInCart: OrderItem | undefined =
    (!!orderItems && !!groupedOrderId
      ? getParsedOrderItems(orderItems)?.find(
          i => i.groupedOrderItemsId?.join('') === groupedOrderId || i.orderItemId === groupedOrderId
        ) || undefined
      : getParsedOrderItems(orderItems)?.find(
          i => i.partNumber === currentProduct?.partNumber || i.partNumber === partNumber
        )) || undefined

  const { addToCartButtonFail, onlyFewPieces } = useProductSoldOutState({
    currentProductPartNumber: partNumber,
    product: currentProduct,
  })

  const { langCode } = useStoreIdentity()

  const isAddToCartBtnInViewport = useIsInViewport(addToCartCtaRef, {})
  const isSoldout = soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT
  const [adjustments, setAdjustment] = useState<Adjustment[]>([])

  useEffect(() => {
    if (isSoldout) document.getElementsByTagName('main')[0].classList.remove('is-pdp')
    else document.getElementsByTagName('main')[0].classList.add('is-pdp')
  }, [isSoldout])

  const isComingBackSoon = soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON

  const { primaryBadge, secondaryBadges } = getBadges(currentProduct, translate, badgeSelector, isRXEnabled)

  const currentOrderItem = useCurrentProductSelection({
    currentProduct: currentProduct || undefined,
    partNumber,
  })
  const { isEditContactLens } = useEditCartItem({ orderItem: currentOrderItem })

  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo>({
    identifier: currentProduct.partNumber,
    active: false,
    recurrency: getFormattedSubscriptionRecurrency(undefined, true),
  })

  const getSortArray = () => {
    switch (productType) {
      case PRODUCT_TYPES_KEYS.ACCESSORIES:
        return pdpAccImageOrder
      case PRODUCT_TYPES_KEYS.CONTACT_LENSES:
        return pdpClImageOrder
      default:
        return pdpFrameImageOrder
    }
  }

  const sortArray = getSortArray()
  const sortedImageByConf: Attachment[] = sortImageByConf(images, sortArray)

  const setSubscriptionAdj = (adjustments: Adjustment[]) => {
    setAdjustment(adjustments)
  }

  const rightColumnProps: ProductRightColumnProps = {
    pdpData,
    type: productType,
    partNumber,
    currentProduct,
    productItems: currentProduct?.items,
    cluster: pdpData?.cluster,
    isLoading,
    onClusterProductClick: onClusterProductClick,
    productInCart: productInCart,
    addToCartButtonFail,
    isSoldout,
    isComingBackSoon,
    isRoxable,
    soldOutStatus,
    ctaRef,
    stickyBarCtaRef,
    placements,
    productQuantity,
    setProductQuantity,
    customerSegments,
    onlyFewPieces,
    adjustments: adjustments,
    setAdjustments: setSubscriptionAdj,
    analyticsPdpData,
    availableQuantity,
    onAddToCartClick,
    liveStockError,
  }

  const getRightColumn = (productType: ProductType | null | string): ReactNode => {
    if (!productType) return null

    if (isContactLenses(productType)) {
      return <ContactLensesPdpRightColumn {...rightColumnProps} isEditing={isEditContactLens} />
    }
    if (isAccessories(productType)) {
      return <AccessoriesPdpRightColumn {...rightColumnProps} />
    }
    if (isCLAccessories(productType)) {
      return <CLAccessoriesPdpRightColumn {...rightColumnProps} />
    }

    return <FramesPdpRightColumn {...rightColumnProps} />
  }

  useEffect(() => {
    !!ctaRef.current && setAddToCartCtaRef(ctaRef)
  }, [])

  useEffect(() => {
    if (isAddToCartBtnInViewport !== undefined) {
      setStickyBarVisible(!isAddToCartBtnInViewport && !!addToCartCtaRef.current)
    }
  }, [isAddToCartBtnInViewport, addToCartCtaRef])

  /**
   * Monetate analytics & Wunderkind
   * Tracks the product on page load, and also on variant change
   */
  useEffect(() => {
    if (pdpData && currentProduct && orderItems) {
      const productId = currentProduct.uniqueID || ''
      const monetateCartItems =
        localStorageUtil.get(MONETATE_CART_ITEMS) ||
        '[]' /* Need to fall back to string to prevent JSON.parse from erroring */
      const productsInCartForMonetate = JSON.parse(monetateCartItems)
      const productPrice = determineAlgoliaPrice(
        currentProduct.x_price,
        customerSegments,
        // SUN products type are to be considered as frames
        productType === PRODUCT_TYPES_KEYS.SUN ? PRODUCT_TYPES_KEYS.FRAMES : productType
      )
      const productName = !!getBrand(currentProduct)
        ? [getBrand(currentProduct), getModelName(currentProduct)].join(' ')
        : getModelName(currentProduct)
      const imgUrl =
        !!sortedImageByConf && sortedImageByConf.length
          ? `${damDomain}${sortedImageByConf[0]?.attachmentAssetPathRaw}`
          : ''

      monetateTrackPdp({
        partNumber,
        productId,
        productsInCart: productsInCartForMonetate,
        pageType: pageType || 'pdp',
      })

      if (window?.wunderkind && isWunderkindEnabledForLocale(langCode)) {
        window.wunderkind.product = {
          exclusion: isSoldout, // TODO: get correct exclusion value
          id: productId,
          sku: partNumber,
          name: productName,
          url: window.location.href,
          imgUrl: imgUrl,
          price: productPrice?.offerPrice ? +productPrice.offerPrice : 0,
          inStock: !isSoldout,
          itemCategory: !!productType ? productType : currentProduct.catalogEntryTypeCode,
        }
      }
    }
  }, [currentProduct, orderItems, pdpData, pageType, partNumber, isSoldout, productType, sortedImageByConf])

  /**
   * if cart returns an error for insufficient inventory,
   * then reset the selected quantity and set it to the available qty
   */
  useEffect(() => {
    if (addCLAccessoriesToCartError) {
      const currentQty = Number(productQuantity[0])
      const availableQty = availableQuantity ? Number(availableQuantity) : 1

      if (currentQty > availableQty) {
        const qty = availableQty.toString()
        setProductQuantity([qty])
      }
    }
  }, [addCLAccessoriesToCartError])

  if (isLoading) {
    return <ProductDetailsSkeleton />
  }
  const soldOutFeatureEnabled = isSoldOutFeatureEnabled()
  const showNavigation = !isDesktop && sortedImageByConf.length > 1
  return (
    <>
      <BreadcrumbLayout
        cid=""
        searchTermText=""
        product={currentProduct}
        breadcrumbsList={breadcrumbs}
        langCode={langCode}
      />
      {!isDesktop && currentProduct && (
        <TrustPilotMiniWidgetContainer>
          <TrustPilotMiniWidget sku={partNumber} />
        </TrustPilotMiniWidgetContainer>
      )}
      <ProductDetailsHead product={currentProduct} />
      <PdpContainer container noVertPadding>
        {areImagesAvailable && !isDesktop && (
          <ProductFullPageGallery
            alt={getProductImageAltLabel(currentProduct)}
            currentImageIndex={currentSlide}
            isVisible={fullPageGalleryVisible}
            images={sortedImageByConf}
            onCloseClick={() => setFullPageGalleryVisible(false)}
          />
        )}

        {areImagesAvailable && isDesktop && (
          <ProductImageZoom
            alt={getProductImageAltLabel(currentProduct)}
            currentImageIndex={currentSlide}
            images={sortedImageByConf}
            isVisible={zoomLayerVisible}
            onCloseClick={() => {
              setZoomLayerVisible(false)
            }}
          />
        )}

        <PdpLeftColumn xs={'fullWidth'} md={8} xl={9}>
          <StyledLeftColumnContainer visible={!zoomLayerVisible}>
            <ProductBadges primaryBadge={primaryBadge} secondaryBadges={secondaryBadges} styleOverride={styles} />
            <SliderWrapper>
              {((!isSoldout && areImagesAvailable) || soldOutFeatureEnabled) && isDesktop && (
                <ProductImagesThumbnails
                  images={sortedImageByConf}
                  currentIndex={currentSlide}
                  brandName={currentProduct?.productAttributes['BRAND']}
                  onThumbnailClick={slideNumber => setCurrentSlide(slideNumber)}
                  thumbnailImageProps={{
                    alt: getProductImageAltLabel(currentProduct),
                    usage: 'PDP',
                    width: 80,
                  }}
                />
              )}

              {!isSoldout || soldOutFeatureEnabled ? (
                areImagesAvailable ? (
                  <ProductImagesMainSlider
                    images={sortedImageByConf}
                    sliderImageProps={{
                      alt: getProductImageAltLabel(currentProduct),
                      srcsetmap: {
                        300: '320w',
                        400: '640w',
                        600: '960w',
                        700: '1280w',
                        900: '1920w',
                      },
                      width: 600,
                      usage: 'PDP',
                    }}
                    onImageClick={index => {
                      setCurrentSlide(index)

                      !isDesktop && setFullPageGalleryVisible(!fullPageGalleryVisible)
                      isDesktop && setZoomLayerVisible(!zoomLayerVisible)
                    }}
                    onSlideChange={index => {
                      setCurrentSlide(index || 0)
                    }}
                    currentSlide={currentSlide}
                    pdpdataloading={loading}
                    shownavigation={showNavigation}
                  />
                ) : (
                  <ProductImageNotAvailable />
                )
              ) : (
                <>
                  <StyledSoldOutBrandIconWrapper>
                    <BrandIcon name={currentProduct?.productAttributes['BRAND']} />
                  </StyledSoldOutBrandIconWrapper>
                  <ProductSoldoutContainer>
                    <ProductSoldoutBanner
                      productType={getProductType(currentProduct)}
                      breadcrumbs={breadcrumbs ?? []}
                    />
                  </ProductSoldoutContainer>
                </>
              )}
            </SliderWrapper>

            {productType && isVmProduct && isVirtualMirrorEligible(currentProduct) && pdpData && (
              <VirtualMirrorCTA
                labelText={translate('VirtualMirror.CTA.TryOn')}
                pdpData={pdpData}
                currentProduct={currentProduct}
              />
            )}
          </StyledLeftColumnContainer>
        </PdpLeftColumn>

        <PdpRightColumn xs={'fullWidth'} md={4} xl={3}>
          <StyledRightColumnContainer visible={!zoomLayerVisible}>
            {getRightColumn(rightColumnContentType)}
          </StyledRightColumnContainer>
        </PdpRightColumn>
      </PdpContainer>

      <PlacementLayout loading={loadingCommercePlacements} pdpData={pdpData} placements={placements ?? []} />

      {isTrustPilotEnabledForLocale(mySite.locale) && (
        <StyledTrustBoxContainer>
          <TrustBox sku={partNumber} productName={currentProduct?.name} />
        </StyledTrustBoxContainer>
      )}
      <PDPStickyBar
        adjustments={adjustments}
        visible={stickybarVisible}
        loading={false}
        stickyBarCtaRef={stickyBarCtaRef}
        soldOutStatus={soldOutStatus}
        subscriptionInfo={subscriptionInfo}
        partNumber={partNumber}
        product={currentProduct}
        images={sortedImageByConf}
        currentIndex={currentSlide}
        addToCartButtonFail={addToCartButtonFail}
        isRoxable={isRoxable}
        productQuantity={productQuantity}
        onAddToCartClick={onAddToCartClick}
      />
    </>
  )
}

export default ProductDetails
