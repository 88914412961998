import { cartSelector, orderItemsSelector } from '@features/order/selector'
import { useSite } from '@foundation/hooks/useSite'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useRefValue } from '@hooks/useRefValue'
import { storeCfgServiceLinksSelector } from '@redux/selectors/site'
import { OrderItem } from '@typesApp/order'
import { ClusterProduct, IProduct } from '@typesApp/product'
import { Config as VMConfig, VMProduct } from '@typesApp/virtualMirror'
import { isRxCart, parseRxOrderItems } from '@utils/rx'
import {
  VIRTUAL_MIRROR_WISHLIST_ITEM_ID,
  VIRTUAL_MIRROR_WISHLIST_TOGGLE,
  virtualMirrorUtils,
} from '@utils/virtualMirrorUtils'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { useAddOrderItem } from '@views/ProductDetails/hooks/useAddOrderItem'
import { useWishlist } from '@views/ProductDetails/hooks/useWishlist'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader, LoaderWrapper, VirtualMirrorContainer, VirtualMirrorWrapper } from './VirtualMirror.style'
import globalConfig from '../../../../configs'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { setCurrentProductBundle } from '@features/product/slice'
import { setPrescriptionLensFormOpen } from '@features/rox/roxSlice'
import { getIsRoxable } from '@utils/productAttributes'
import { sessionStorageUtil } from '@foundation/utils/storageUtil'

const VIRTUAL_MIRROR_CONTAINER_ID = 'virtual-mirror-container'
const FALLBACK_VMMV_URL = globalConfig.vmmv.fallbackScript

interface VirtualMirrorProps {
  onClose: (value: boolean) => void
  cluster?: ClusterProduct[]
  currentProduct?: IProduct
}

const VirtualMirror: React.FC<VirtualMirrorProps> = ({ onClose, cluster, currentProduct }) => {
  const { mySite } = useSite()
  const damDomain: string = mySite.xStoreCfg
    ? mySite.xStoreCfg['damDomain'] || globalConfig.defaultDamDomain
    : globalConfig.defaultDamDomain
  const customerSegments = useCustomerSegmentsUtil()
  const dispatch = useDispatch()
  const serviceLinks = useSelector(storeCfgServiceLinksSelector)
  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)

  const [addedProductUpc, setAddedProductUpc] = useState<VMProduct | undefined>(undefined)
  const [wishlistedProduct, setWishlistedProduct] = useState<VMProduct | undefined>(undefined)
  const { basePath } = useStoreIdentity()

  const termsLink = `${window.location.origin}${basePath}${serviceLinks?.terms}`

  const VIRTUAL_MIRROR_SCRIPT_URL = mySite.xStoreCfg?.VM_SCRIPT_SRC || FALLBACK_VMMV_URL

  const isClusterPricesEmpty = useRef(false)
  const mappedPrices = useMemo(() => {
    if (cluster) {
      const productPrices = cluster
        .map(cl => virtualMirrorUtils.getProductPrices(cl.items?.[0] || cl, customerSegments))
        .filter(price => price.current.text !== '')

      if (productPrices.length > 0) return productPrices
      else isClusterPricesEmpty.current = true
    }

    if (currentProduct) {
      const productPrices = [virtualMirrorUtils.getProductPrices(currentProduct, customerSegments)].filter(
        price => price.current.text !== ''
      )

      if (productPrices.length > 0) return productPrices
    }

    return []
  }, [cluster, currentProduct, customerSegments])

  const mappedData = useMemo(() => {
    if (cluster && !isClusterPricesEmpty.current) {
      return virtualMirrorUtils.setCurrentUpc(
        cluster.map(cl => virtualMirrorUtils.convertServerProduct(cl.items?.[0] || cl, damDomain)),
        currentProduct?.partNumber ? currentProduct?.partNumber : ''
      )
    }
    if (currentProduct) {
      return [virtualMirrorUtils.convertServerProduct(currentProduct, damDomain)]
    }
    return []
  }, [cluster, currentProduct, damDomain])

  const closeVm = useCallback(() => {
    virtualMirrorUtils.unmountVirtualMirror()
    onClose(false)
  }, [onClose])

  const getSelectedProduct = useCallback(
    (type: string) => {
      if (cluster) {
        return cluster.find(
          cp => cp.items?.[0]?.name === (type === 'cart' ? addedProductUpc?.upc : wishlistedProduct?.upc)
        )?.items?.[0]
      }
      return currentProduct
    },
    [currentProduct, cluster, addedProductUpc, wishlistedProduct]
  )

  const productInCart: OrderItem | undefined = useMemo(() => {
    return (
      (isRxCart(cart?.orderExtendAttribute) ? parseRxOrderItems(orderItems) : orderItems).find(
        i => i.partNumber === getSelectedProduct('cart')?.partNumber
      ) || undefined
    )
  }, [isRxCart, cart, orderItems, parseRxOrderItems, getSelectedProduct])

  const { addToCart } = useAddOrderItem(
    productInCart,
    getSelectedProduct('cart'),
    getSelectedProduct('cart')?.partNumber || ''
  )
  const { determineWishListAction, wishlistedItems } = useWishlist(currentProduct)
  const wishlistedUpcs = wishlistedItems.map(item => item.partNumber)

  const locale = localeLangCountryUtil(mySite.locale.replace('_', '-'), '-')

  const openPrescriptionLenses = () => {
    dispatch(setCurrentProductBundle({ product: currentProduct }))
    dispatch(setPrescriptionLensFormOpen(true))
  }

  useEffect(() => {
    if (!addedProductUpc) return

    const isRoxable = currentProduct && getIsRoxable(currentProduct)
    if (isRoxable) {
      openPrescriptionLenses()
    } else {
      addToCart()
      virtualMirrorUtils.unmountVirtualMirror()
    }
  }, [addedProductUpc])

  const vmConfig: VMConfig = useMemo(() => {
    return {
      selector: `#${VIRTUAL_MIRROR_CONTAINER_ID}`,
      style: '',
      locale,
      termsAndConditions: termsLink,
      onPrivacyReject: closeVm,
      store: { storeId: mySite.storeID, id: mySite.storeID, type: 'rb' },
      vmInit: {
        // Corresponds to VMCore initializationParams.privacy.source
        source: globalConfig.vmmv.source,
        // Corresponds to VMCore initializationParams.options.key
        key: globalConfig.vmmv.key,
        // Corresponds to VMCore initializationParams.options.channel
        channel: globalConfig.vmmv.channel,
        // Corresponds to VMCore initializationParams.options.brand
        brand: globalConfig.vmmv.brand,
        // glasses environment
        glassesEnv: globalConfig.vmmv.glassesEnv,
      },
      products: mappedData,
      prices: mappedPrices,
      wishlistedUpcs,
      analytics: {
        adobeSessionId: window.utag.data.Session_Id.toString(),
        pagePlatform: window.utag.data.Page_Platform.toString(),
        pageBrand: window.utag.data.Page_Brand.toString(),
        storeId: mySite.storeID,
        sourcePosition: 'PdpStnd',
        pageEnvironment: window.utag.data.Page_Environment?.toString(),
        storeRegion: mySite.country,
        storeCompany: window.utag.data.tealium_profile,
        storeGlobalId: mySite.storeID,
        source: 'Pdp',
        style: '',
      },
      fromStore: globalConfig.vmmv.fromStore,
      isTryOnEnabled: globalConfig.vmmv.isTryOnEnabled,
      isTakeScreenshotEnabled: globalConfig.vmmv.isTakePictureEnabled,
      isPictureModeEnabled: globalConfig.vmmv.isPictureModeEnabled,
      isUploadPictureEnabled: globalConfig.vmmv.isUploadPictureEnabled,
      isTakePictureEnabled: globalConfig.vmmv.isTakePictureEnabled,
      isVideoModeEnabled: globalConfig.vmmv.isVideoModeEnabled,
      isQrCodeEnabled: globalConfig.vmmv.isQrCodeEnabled,
      onClose: closeVm,
      onAddToBag: pr => {
        setAddedProductUpc(pr)
      },
      onToggleWishlist: async pr => {
        try {
          await determineWishListAction(true)

          setWishlistedProduct(pr)
          return Promise.resolve()
        } catch (error) {
          return Promise.reject()
        }
      },
    }
  }, [mySite, mappedData, termsLink, closeVm, wishlistedUpcs, setAddedProductUpc, determineWishListAction])
  //upload take photo
  const init = useCallback(() => {
    virtualMirrorUtils.initVirtualMirror(vmConfig, VIRTUAL_MIRROR_SCRIPT_URL)
  }, [vmConfig, VIRTUAL_MIRROR_SCRIPT_URL])

  const initFn = useRefValue(init)

  useEffect(() => {
    initFn.current()

    if (wishlistedUpcs.includes(currentProduct?.partNumber || '')) {
      sessionStorageUtil.set(VIRTUAL_MIRROR_WISHLIST_TOGGLE, 'wishlisted')
    } else {
      sessionStorageUtil.remove(VIRTUAL_MIRROR_WISHLIST_TOGGLE)
    }

    return () => {
      closeVm()
      sessionStorageUtil.remove(VIRTUAL_MIRROR_WISHLIST_TOGGLE)
      sessionStorageUtil.remove(VIRTUAL_MIRROR_WISHLIST_ITEM_ID)
    }
  }, [closeVm])

  return (
    <VirtualMirrorWrapper>
      <VirtualMirrorContainer id={VIRTUAL_MIRROR_CONTAINER_ID}>
        <LoaderWrapper>
          <Loader size={60} />
        </LoaderWrapper>
      </VirtualMirrorContainer>
    </VirtualMirrorWrapper>
  )
}

export default VirtualMirror
